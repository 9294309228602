.section-services {
  padding: 8rem 2rem;
  color: white;
}

.services-container {
  margin: 0 auto;
  padding: 0;
  max-width: 40rem;
}

.services-title {
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 1.8rem;
}

.services__subtitle {
  align-items: center;
  display: flex;
  justify-content: center;
  color: $matteWhite;
  padding-bottom: $m-size;
  font-size: 1.6rem;
}

.services__content {
  display: flex;
  font-size: 1.6rem;
  flex-direction: column;
  text-align: justify;
  letter-spacing: 1.2;
  color: $matteWhite;
  margin: 0 auto;
}

.services__list-title {
  color: #c7c9cc;
  display: flex;
  justify-content: center;
}

.services__list-content {
  color: #c7c9cc;
  padding: 1.6rem;
}

.services-columns {
  float: left;
  width: 50%;
  padding: 0 10px;
  margin-bottom: 0.75rem;
}
.services-img {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 36rem;
  }
}

.svg-inline--fa.fa-w-16 {
  width: 2em;
  color: #57b894;
}

@media (min-width: $tablet-portrait) {
  .services__list-title {
    color: #c7c9cc;
    display: flex;
    justify-content: center;
  }

  .services-img img {
    max-width: 60rem;
  }

  .services__content {
    font-size: 2rem;
  }
  .services-container {
    margin: 0 auto;
    padding: 0;
    max-width: 60rem;
  }
  .section-services {
    padding: 14rem 8rem;
    color: white;
  }
}

@media (min-width: $desktop-breakpoint) {
  .section-services {
    padding: 14rem 8rem;
    color: white;
  }
  .services-title {
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
  }

  .services-container {
    max-width: none;
  }
  .services-title {
    font-size: 2.8rem;
  }

  .services__subtitle {
    align-items: center;
    display: flex;
    justify-content: center;
    color: $matteWhite;
    padding-bottom: $m-size;

    h1 {
      font-size: 1.6em;
    }
  }

  .services__content {
    display: flex;
    font-size: 2rem;
    justify-content: space-between;
    flex-direction: row;
    padding: $m-size;
    text-align: justify;
    letter-spacing: 1.2;
    color: $matteWhite;
    margin: 0 auto;
    padding: 2rem 1.6rem;
    max-width: 135rem;
  }

  .services__list-content {
    color: #c7c9cc;
    padding: 1.6rem;
  }

  .services-columns {
    float: left;
    width: 50%;
    padding: 0 10px;
    margin-bottom: 0.75rem;
  }
  .services-img {
    margin-right: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
    }
  }

  .svg-inline--fa.fa-w-16 {
    width: 2em;
    color: #57b894;
  }
}
