.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.modal-box {
  width: 98%;
  height: 90%;
  background-color: rgb(7, 7, 7);
  box-shadow: 0 0 15 rgba(0, 0, 0, 0.5);
  padding: 0;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  animation-name: grow-box;
  animation-duration: 1s;
  transition-timing-function: ease-in-out;
}

.modal__button {
  display: flex;
  justify-content: center;
}

.modal__title {
  display: flex;
  justify-content: center;
  color: white;
}

.modal__description {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: justify;
  padding: $m-size;
  color: #c7c9cc;
}

@keyframes grow-box {
  0% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.9;
    width: 100rem;
    height: 80rem;
  }
}

@media (min-width: $desktop-breakpoint) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.9rem;
  }

  .modal-box {
    width: 80rem;
    height: 40rem;
    background-color: rgb(7, 7, 7);
    box-shadow: 0 0 15 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    animation-name: grow-box;
    animation-duration: 1s;
    transition-timing-function: ease-in-out;
  }

  .modal__button {
    display: flex;
    justify-content: center;
  }

  .modal__description {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    padding: $m-size;
  }

  @keyframes grow-box {
    0% {
      opacity: 0.2;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.6;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.9;
      width: 100rem;
      height: 80rem;
    }
  }
}
