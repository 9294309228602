.projects-section {
  padding: 8rem 2rem;
  color: white;
}

.projects__container {
  margin: 0 auto;
  padding: 0;
  max-width: 40rem;
}

.projects-title {
  font-family: "Raleway", sans-serif;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
}

.projects-content {
  color: #c7c9cc;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  justify-content: center;
  text-align: center;
  margin: 2rem 0 2rem 0;
  div {
    margin-top: 2rem;
  }
}

.projects__button {
  display: flex;
  justify-content: center;
}

@media (min-width: $tablet-portrait) {
  .projects__container {
    margin: 0 auto;
    padding: 0;
    max-width: 50rem;
  }
  .projects-content {
    text-align: center;
    margin: 2rem;
    padding-bottom: 0;
    font-size: 2rem;
  }
  .projects-title {
    font-size: 2rem;
    margin: 5rem;
  }
  .projects-section {
    padding: 8rem;
    color: white;
  }
  .projects-section {
    padding: 8rem;
    color: white;
  }
}

@media (min-width: $desktop-breakpoint) {
  .projects-section {
    padding: 14rem 8rem;
    color: white;
  }

  .projects__container {
    margin: 0 auto;
    padding: 0;
    max-width: 52rem;
  }
  .projects-title {
    font-size: 2.8rem;
  }
  .projects-content {
    color: #c7c9cc;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    margin: 2rem;
    justify-content: center;
    text-align: center;
  }

  .projects__button {
    display: flex;
    justify-content: center;
  }
}
