.section-skills {
  padding: 8rem 2rem;
  color: white;
}

.skills {
  text-align: center;
  margin: 0 auto;
  max-width: 125rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.skills-container {
  margin: 0 auto;
  padding: 0;
  max-width: 40rem;
}

.skills__content {
  color: #c7c9cc;
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  padding: 0;
  text-align: center;
  margin: 2rem 0 2rem 0;
}

.skills-icon {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
  padding: 0.5rem;
  opacity: 0.7;
  margin: 1.3rem;
  display: inline-block;
  transition: 0.3s ease;
}

.icon-content {
  font-size: 3.5rem !important;
  font-weight: 300 !important;
  line-height: 1.2 !important;
  text-align: center;
}

.icon-font {
  font-family: "Orbitron", sans-serif;
  color: white;
  font-size: 1rem;
}

@media (min-width: $tablet-portrait) {
  .skills {
    grid-template-columns: repeat(3, 1fr);
  }
  .skills-container {
    margin: 0 auto;
    padding: 0;
    max-width: 45rem;
  }
  .skills__content {
    text-align: center;
    font-size: 2rem;
    margin: 2rem;
  }
  .section-skills {
    padding: 8rem;
    color: white;
  }
}

@media (min-width: $desktop-breakpoint) {
  .section-skills {
    padding: 14rem 8rem;
    color: white;
  }

  .skills-container {
    margin: 0 auto;
    padding: 0;
    max-width: 52rem;
  }
  .skills {
    text-align: center;
    margin: 0 auto;
    max-width: 125rem;
    display: inherit;
  }

  .skills__content {
    color: #c7c9cc;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    text-align: center;
  }

  .skills-icon {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 0.5rem;
    padding: 0.5rem;
    opacity: 0.7;
    margin: 1.3rem;
    display: inline-block;
    min-width: 180px;
    transition: 0.3s ease;
    &:hover {
      background: #ffffffc2;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      opacity: 1;
    }
  }

  .icon-content {
    font-size: 6.5rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
    text-align: center;
  }

  .icon-font {
    font-family: "Orbitron", sans-serif;
    color: white;
    font-size: 1.5rem;
  }
}
