.navbar {
  background: $transparentColor;
  color: white;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2.2rem;
  right: 0;
  left: 0;
}

.scroll {
  background: #8e6994eb;
  color: black;
  font-weight: bold;
  z-index: 1;
}

.nav-width {
  margin-top: 20px;
}

.navbar-logo {
  background-image: url(https://media.giphy.com/media/n3xSURtNE62t2/giphy.gif);
  background-size: cover;
  background-position: center center;
  color: transparent;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 4rem;
  font-family: "Raleway", sans-serif;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
}

.svg-inline--fa.fa-w-20 {
  width: 0.75em !important;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  color: white;
  opacity: 0.7;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2.5rem;
  font-family: "Montserrat";
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  color: #bb5d8c;
  text-decoration: underline;
}

.fa-bars {
  color: #fff;
  font-size: 2.8rem;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: absolute;
  }

  .nav-item {
    height: auto;
  }
  .navbar {
    background: #000918;
    position: absolute;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    align-items: center;
    justify-content: center;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .navbar-logo {
    background-image: url(https://media.giphy.com/media/n3xSURtNE62t2/giphy.gif);
    background-size: cover;
    background-position: center center;
    color: transparent;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 3rem;
    font-family: "Raleway", sans-serif;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    text-transform: uppercase;
  }

  .nav-menu.active {
    background: #000918;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: linear-gradient(
      to right,
      #b8cbb8 0%,
      #b8cbb8 0%,
      #b465da 0%,
      #cf6cc9 33%,
      #ee609c 66%,
      #ee609c 100%
    );
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 22%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2.8rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #bb5d8c;
    transition: 250ms;
  }
}
