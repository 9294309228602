// colors
$dark-grey: #333333;
$grey: #666;
$blue: #1c88bf;
$dark-blue: #364051;
$off-white: #f7f7f7;
$primaryColor: #476a2e;
$transparentColor: #00000000;
$matteWhite: #c7c9cc;
$lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
$starWine: linear-gradient(
  to right,
  #b8cbb8 0%,
  #b8cbb8 0%,
  #b465da 0%,
  #cf6cc9 33%,
  #ee609c 66%,
  #ee609c 100%
);
// Font Size
$font-size-large: 6rem;
$font-size-medium: 4rem;
$font-size-small: 1.18rem;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 10.8rem;
%col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
$mobile-landscape: 35rem;
$tablet-portrait: 48rem;
$tablet-landscape: 62rem;
$desktop-breakpoint: 60rem;
$desktop-breakpoint-larger: 75rem;

$smallWidth: 85vw;
$fullWidth: 1170px;

$mainTransition: all 0.3s linear;
