.contact-section {
  padding: 15rem 2rem;
  color: white;
}
.contact-container {
  margin: 0 auto;
  padding: 0;
  max-width: 52rem;
  div {
    position: relative;

    a {
      margin-top: -37px;
      margin-bottom: 30px;
    }
  }
}
.contact-content {
  color: #c7c9cc;
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  padding: 0;
  text-align: center;
}

.contact-content__email {
  margin-top: 2rem;
  justify-content: center;
  text-align: center;
}

.footer-contact {
  margin-top: 6rem;

  a {
    margin-right: 1rem;
  }
  i {
    color: #ce89bf !important;
  }
  div {
    margin-left: 2rem;
    font-family: "Montserrat", sans-serif;
  }
}

@media (min-width: $tablet-portrait) {
  .contact-container {
    max-width: 46rem;
    div {
      position: relative;

      a {
        margin-top: -67px;
      }
    }
  }
  .contact-content {
    text-align: center;
  }
}

@media (min-width: $desktop-breakpoint) {
  .contact-section {
    padding: 14rem 8rem;
    color: white;
  }

  .contact-container {
    margin: 0 auto;
    padding: 0;
    max-width: 120rem;
    div {
      position: relative;

      a {
        margin-bottom: 0;
      }
    }
  }

  .contact-content {
    color: #c7c9cc;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    padding: 0;
    text-align: center;
    padding: 4rem 20rem;
  }

  .contact-content__email {
    margin-top: 2rem;
    justify-content: center;
    text-align: center;
  }
}
