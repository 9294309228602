.button {
  background: $starWine;
  border: none;
  border-radius: 35px;
  color: $matteWhite;
  display: inline-block;
  font-weight: 300;
  line-height: 1;
  padding: $s-size;
  text-decoration: none;
  font-size: 1.3rem;

  span {
    font-size: 3rem;
  }
}

.button--link {
  background: none;
}

.button--secondary {
  background: #888;
}
