.header {
  background-attachment: fixed;
  background-position: center bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.header__content {
  color: $off-white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media (min-width: $mobile-landscape) {
    flex-direction: row;
  }
  @media (min-width: $tablet-portrait) {
    flex-direction: column;
  }
}

.lottie {
  display: grid;
  grid-template-columns: auto;
}

.lotties-license__header {
  display: flex;
  justify-content: center;
  color: #ffffff85;
  font-size: 10px;
  position: relative;
  left: 1rem;
  top: -6rem;
  transform: rotate(-30deg);
}
.header__title {
  color: white;
  font-size: $font-size-medium;
  margin: 0 0 $m-size 0;
  line-height: 1;
  font-family: "Montserrat";
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh;
  letter-spacing: 1px;

  @media (min-width: $mobile-landscape) {
    height: auto;
  }

  h1,
  p {
    background-image: url(https://media.giphy.com/media/n3xSURtNE62t2/giphy.gif);
    background-size: cover;
    background-position: center center;
    color: transparent;
    font-family: "Montserrat", sans-serif;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 1;
    margin: 10px 0;
  }
}

@media (min-width: $tablet-portrait) {
  .header__title {
    h1,
    p {
      font-size: 6rem;
    }
  }
  .lotties-license__header {
    left: 0rem;
    top: -9rem !important;
  }
}

@media (min-width: $mobile-landscape) {
  // .lotties-license__header {
  //   left: 10rem;
  //   top: 72% !important;
  // }
}

@media (min-width: $desktop-breakpoint) {
  .header {
    padding-top: 0;
    justify-content: center;
  }

  .lottie {
    width: 400px !important;
    height: 600px !important;
  }

  .lotties-license__header {
    left: 0rem;
    top: -9rem;
  }
  .header__title {
    height: auto;

    h1,
    p {
      font-size: 6rem;
    }
  }
}

@media (min-width: $desktop-breakpoint-larger) {
  .header {
    background-attachment: fixed;
    background-position: center bottom;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100vh;
    padding-top: 18rem;
  }

  .header__content {
    color: $off-white;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .lottie {
    width: 400px !important;
    height: 600px !important;
  }
  .header__title {
    color: white;
    margin: 0 0 $m-size 0;
    line-height: 1;
    font-family: "Montserrat";
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    letter-spacing: 1px;

    h1,
    p {
      background-image: url(https://media.giphy.com/media/n3xSURtNE62t2/giphy.gif);
      background-size: cover;
      background-position: center center;
      color: transparent;
      font-family: "Montserrat", sans-serif;
      -moz-background-clip: text;
      -webkit-background-clip: text;
      text-transform: uppercase;
      font-size: 6rem;
      line-height: 1;
      margin: 10px 0;
    }
  }
}
