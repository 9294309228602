.about-section {
  color: $off-white;
  padding: 8rem 2rem;
  @media (min-width: $tablet-portrait) {
    padding: 8rem;
  }
}

.about-title {
  font-family: "Raleway", sans-serif;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.8rem;
}

.about__content {
  color: $matteWhite;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  justify-content: space-between;
  padding: $m-size;
  text-align: justify;
  letter-spacing: 1.2;
  img {
    position: relative;
  }

  @media (min-width: $tablet-landscape) {
    font-size: 2rem;
    flex-direction: row;
  }
  @media (min-width: $tablet-portrait) {
    font-size: 2rem;
  }
}

.about-img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin: 0 auto;
  align-items: center;
  margin-top: 20px;

  @media (min-width: $tablet-portrait) {
    width: 300px;
    height: 300px;
  }
}

@media (min-width: $desktop-breakpoint) {
  .about-section {
    color: $off-white;
    padding: 14rem 8rem;
  }

  .about-title {
    font-family: "Raleway", sans-serif;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 2.8rem;
  }

  .about__content {
    color: $matteWhite;
    display: flex;
    flex-direction: column;
    font-size: $font-size-small;
    justify-content: space-between;
    padding: $m-size;
    text-align: justify;
    letter-spacing: 1.2;
    img {
      position: relative;
    }

    @media (min-width: $tablet-landscape) {
      font-size: 2rem;
      flex-direction: row;
    }
    @media (min-width: $tablet-portrait) {
      font-size: 2rem;
      flex-direction: row;
    }
  }

  .about-img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin: 0 auto;
    align-items: center;
    position: absolute;
    left: 50%;

    @media (min-width: $desktop-breakpoint) {
      min-width: 260px;
      min-height: 260px;
      flex-direction: column;
      top: 50%;
      left: 30%;
      transform: translateY(-50%);
    }
  }
}
