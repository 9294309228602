.list-title {
  color: $matteWhite;
  display: flex;
  justify-content: center;
}

h1 {
  font-size: 1.4em;
}
.list-item {
  border: 1px solid darken($off-white, 7%);
  border-radius: 10px;
  color: $off-white;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  text-decoration: none;
  transition: background 0.3s ease;
  min-height: 100%;
}

.list-item img {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.list-item footer {
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.12);
}

.list-item p {
  margin-bottom: 1.25rem;
  color: $off-white;
}

.list-info {
  align-items: center;
}

.devicon-html5-plain.colored,
.devicon-css3-plain.colored,
.devicon-css3-plain.colored,
.devicon-react-plain-wordmark.colored {
  color: #61dafb;
  font-size: 2.8rem;
}

.devicon-css3-plain.colored,
.devicon-css3-plain-wordmark.colored {
  font-size: 2.8rem;
}

.devicon-javascript-plain.colored {
  font-size: 2.8rem;
}

[class^="devicon-"],
[class*=" devicon-"] {
  font-size: $l-size;
  margin-right: 0.75rem;
}

.list-info--content {
  align-items: center;
  color: $matteWhite;
  justify-content: center;
  padding: $m-size;
  &:hover {
    background: none;
  }
}

.list-info__icons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    display: flex;
  }
}

.list-icons {
  background: red;
  border-radius: 0.5rem;
  padding: 0.5rem;
  opacity: 0.7;
  margin: 1.3rem;
  display: inline-block;
  min-width: 180px;
  transition: 0.3s ease;
}
.list-technologies__icons {
  font-size: 2.4rem !important;
}

.list-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.name-icons span {
  position: relative;
  top: 12px;
  left: -4rem;
  font-size: 11px;
  font-family: "Montserrat";
}

.list-columns {
  width: 100%;
  padding: 0;
  margin-bottom: 0.75rem;
  flex: 0 1 50%;
}

.list-item__title {
  margin: 0;
  font-size: 1.6rem;
  font-family: "Montserrat";
}

.list-item__subtitle {
  color: $grey;
  font-size: $font-size-small;
}

.list-item__data {
  margin: $s-size 0 0 0;

  @media (min-width: $desktop-breakpoint) {
    margin: 0;
    padding-left: $s-size;
  }
}

@media (min-width: $desktop-breakpoint) {
  .list-title {
    color: $matteWhite;
    display: flex;
    justify-content: center;
  }
  .list-item {
    border: 1px solid darken($off-white, 7%);
    border-radius: 10px;
    color: $off-white;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    text-decoration: none;
    transition: background 0.3s ease;
    min-height: 100%;
  }

  h1 {
    font-size: 2em;
  }
  .list-item img {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 100%;
  }

  .list-item footer {
    padding: 1.5rem 2rem;
    background: rgba(255, 255, 255, 0.12);
  }

  .list-item p {
    margin-bottom: 1.25rem;
    color: $off-white;
  }

  .list-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .devicon-html5-plain.colored,
  .devicon-css3-plain.colored,
  .devicon-css3-plain.colored,
  .devicon-react-plain-wordmark.colored {
    color: #61dafb;
    font-size: 2.8rem;
  }

  .devicon-css3-plain.colored,
  .devicon-css3-plain-wordmark.colored {
    font-size: 2.8rem;
  }

  .devicon-javascript-plain.colored {
    font-size: 2.8rem;
  }

  [class^="devicon-"],
  [class*=" devicon-"] {
    font-size: $l-size;
    margin-right: $s-size;
  }

  .list-info--content {
    align-items: center;
    color: $matteWhite;
    justify-content: center;
    padding: $m-size;
    &:hover {
      background: none;
    }
  }

  .list-info__icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list-icons {
    background: red;
    border-radius: 0.5rem;
    padding: 0.5rem;
    opacity: 0.7;
    margin: 1.3rem;
    display: inline-block;
    min-width: 180px;
    transition: 0.3s ease;
  }
  .list-technologies__icons {
    font-size: 3.5rem !important;
  }

  .list-columns {
    float: left;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 0.75rem;
  }

  .list-item__title {
    margin: 0;
    word-break: break-all;
    font-size: 2rem;
    font-family: "Montserrat";
  }

  .list-item__subtitle {
    color: $grey;
    font-size: $font-size-small;
  }

  .list-item__data {
    margin: $s-size 0 0 0;

    @media (min-width: $desktop-breakpoint) {
      margin: 0;
      padding-left: $s-size;
    }
  }
}
