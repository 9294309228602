* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  color: $dark-grey;
  font-family: Helvetica, Arial, sans-serif;
  font-size: $m-size;
  line-height: 1.6;
  // background: linear-gradient(to right, #eb5757, #000000);
  background: #000918;
}
button,
nav {
  cursor: pointer;
}
button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
  display: block;
}
