.section-portfolio {
  padding: 8rem 2rem;
}

.content-container {
  margin: 0 auto;
  padding: 0;
  max-width: 126rem;
}
// .button-details {
//   display: none;
// }
.devicon-chrome-plain {
  color: white;
}
.devicon-github-original {
  color: white;
}
.icon-color {
  color: white !important;
}

@media (min-width: $desktop-breakpoint) {
  .section-portfolio {
    padding: 14rem 8rem;
  }
  // .button-details {
  //   display: none;
  // }
  .content-container {
    margin: 0 auto;
    padding: 0 $m-size;
    max-width: 126rem;
  }
}
